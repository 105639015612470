export default [
  {

    path: '/',
    component: () => import('@/layouts/SiteLayout/EcevLayout.vue'),
    meta: {
      layout: 'full',
    },
    children: [
      {
        path: '/',
        name: 'site',
        component: () => import('@/views/site/Home.vue'),
      },
      // {
      //   path: '/generer/:type',
      //   name: 'generate',
      //   component: () => import('@/views/site/GenerateCev.vue'),
      // },
    ],
  }

]