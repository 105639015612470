import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
// eslint-disable-next-line import/no-cycle
import authStore from '@/store/authStore'
import verticalMenu from '@/store/vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState(),
  ],
  modules: {
    app,
    authStore,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
