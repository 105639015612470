export default [

  // *===============================================---*
  // *--------- Site Web  ---------------------------------------*
  // *===============================================---*
  {
    path: '/site',
    component: () => import('@/layouts/SiteLayout/SiteLayout.vue'),
    meta: {
      layout: 'full',
    },
    children: [
      // Home Page
      {
        path: '/site',
        name: 'accueil',
        component: () => import('@/views/site/Index.vue'),
      },
    ],
  },
]
