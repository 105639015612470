/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueChatScroll from 'vue-chat-scroll'
import VueDragResize from 'vue-drag-resize'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import VueCarousel from 'vue-carousel'
import AOS from 'aos'
import 'aos/dist/aos.css'
// import './index.css'

// import Toast from 'vue-toastification'
import router from '@/router'
import store from '@/store'

// Global Components
import '@/global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'leaflet-geosearch/dist/geosearch.css'
import '@/@fake-db/db'
// Import the CSS or use your own!
// import 'vue-toastification/dist/index.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { localize } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import Echo from 'laravel-echo'
import App from './App.vue'
import i18n from './i18n'

// axios.defaults.withCredentials = true;
Vue.prototype.configPhoneNumber = {
  countrySelectorLabel: 'Code pays',
  countrySelectorError: 'Choisir un pays',
  phoneNumberLabel: 'Numéro de téléphone',
  example: 'Exemple :',
}
localize('fr', fr)
// const options = {
//   // You can set your default options here
// }
Vue.component('vue-drag-resize', VueDragResize)

// Vue.use(Toast, options)
Vue.use(VueCarousel)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)


window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'edd99ce2edffe7f7b225',
  cluster: 'eu',
})
window.Echo.channel('reponse-expertise').listen('.reponse-expertise', event => {
  console.log(event)
})
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueChatScroll)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
new Vue({
  router,
  store,

  beforeCreate() {
    store.commit('initializeAuth')
  },

  i18n,
  mounted() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
