// eslint-disable-next-line import/no-cycle
import store from '@/store'

export default [

  // *===============================================---*
  // *--------- ROUTES ADMINISTRATEURS  ---------------------------------------*
  // *===============================================---*
  {
    path: '/espace-admin',
    redirect: '/espace-admin/dashboard',
    component: () => import('@/layouts/espace-admin/AdminLayout.vue'),
    beforeEnter: (to, from, next) => {
      const data = JSON.parse(store.state.authStore.user)
      if (data.role === 'ROLE_ADMIN') next()
      else next({ name: 'misc-not-authorized' })
    },
    meta: {
      layout: 'vertical',
      redirectIfLoggedIn: true,
    },
    children: [
      // Dashboard
      {
        path: '/espace-admin/dashboard',
        name: 'espace-admin.dashboard',

        component: () => import('@/views/app/espace-admin/Home.vue'),

        meta: {
          pageTitle: 'Tableau de bord',
          redirectIfLoggedIn: true,

          breadcrumb: [
            { text: 'Tableau de bord', active: true },
          ],
        },
      },
      // Activation de compte
      {
        path: '/espace-admin/activate-account',
        name: 'espace-admin.activateaccount',
        component: () => import('@/views/app/espace-admin/settings/activate-account/ActivateAccount.vue'),
        meta: {
          pageTitle: 'Activation de compte',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Activation de compte',
              active: true,

            },
          ],
        },
      },
      // Manage User
      {
        path: '/espace-admin/manage-user',
        name: 'espace-admin.manage.user',
        component: () => import('@/views/app/espace-admin/settings/activate-account/ManageUser.vue'),
        meta: {
          pageTitle: 'Gestion des utilisateurs',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Gestion des utilisateurs',
              active: true,

            },
          ],
        },
      },

      // Abonnements
      {
        path: '/espace-admin/abonnements',
        name: 'espace-admin.typeabonnement',
        component: () => import('@/views/app/espace-admin/settings/abonnement/Abonnement.vue'),
        meta: {
          pageTitle: 'Type d\'abonnement',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Type d\'abonnement',
              active: true,

            },
          ],
        },
      },
      // Profile
      {
        path: '/espace-admin/profile',
        name: 'espace-admin.profile',
        component: () => import('@/views/app/espace-admin/settings/activate-account/manage-profile/AccountSetting.vue'),
        meta: {
          pageTitle: 'Gestion de profil',
          redirectIfLoggedIn: true,

          breadcrumb: [
            {
              text: 'Tableau de bord',
              to: '/espace-admin/dashboard',

            },
            {
              text: 'Gestion de profil',
              active: true,

            },
          ],
        },
      },
    ],
  },
]
